import React from 'react';
import { View, ScrollView, Text, TouchableOpacity, StyleSheet, useWindowDimensions } from 'react-native';
import { AppCell } from './components/AppCell';
import { ImageBackground } from 'react-native-web';

function App() {
  const size = useWindowDimensions();
  return (
    <ImageBackground source={require('./assets/images/background.png')} style={[StyleSheet.absoluteFill, { backgroundColor: 'gray', alignItems: 'center', justifyContent: 'center' }]}>
      <ScrollView style={[StyleSheet.absoluteFill, { flex: 1, backgroundColor: 'rgba(0,0,0,0.35)' }]} contentContainerStyle={{ paddingTop: 40, alignItems: 'center' }}>
        <AppCell name={'Melon Skin Editor'} short_desc={'Melon PG Skin Editor: Your ultimate tool for pixel-perfect skin customization.'} icon={require('./assets/images/icon_1.png')} />
      </ScrollView>
    </ImageBackground>
  );
}

export default App;
