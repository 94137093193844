import { TouchableOpacity, Image, View, Text } from "react-native";

export function AppCell({
	name,
	short_desc,
	icon,

}) {
	return <TouchableOpacity
		style={{
			width: '50%',
			minWidth: 350,
			aspectRatio: 2.5,
			borderRadius: 20,
			padding: 20,
			backgroundColor: 'rgba(0,0,0,0.8)',
			borderWidth: 1, borderColor: '#1dafad',
			maxWidth: 600,
			flexDirection: 'row'
		}}>
		<Image source={icon} style={{ height: '100%', aspectRatio: 1, borderRadius: 10 }} />
		<View style={{ marginLeft: 20, flex: 1, }}>
			<Text adjustsFontSizeToFit={true} numberOfLines={1} style={{ fontWeight: 'bold', fontSize: 25, color: 'white' }}>{name}</Text>
			<Text numberOfLines={3} adjustsFontSizeToFit={true} style={{ fontSize: 16, color: 'white', marginTop: 10 }}>{short_desc}</Text>
		</View>
	</TouchableOpacity>
}
